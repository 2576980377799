import './index.module.scss';
import Helmet from 'react-helmet';
import Layout from '../../layout';
import Header from '../../components/header/header';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`About Us `}>
        {/* General tags */}
        <meta name="description" content="" />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`About Us`} />
        <meta property="og:description" content="" />
        <meta property="og:url" content={''} />
        <meta property="og:site_name" content={''} />
        <meta property="og:image" content="" />
      </Helmet>
      <div>
        <Header></Header>
        asdasd
      </div>
    </Layout>
  );
}

export default Index;
